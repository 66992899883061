import React from "react";

//Style
import "./JobOfferAdviceSection.css";

const JobOfferAdviceSection = () => {
  return (
    <section className="job-offer-advice-section">
      <div className="job-offer-advice-subcontainer">
        <h1 className="joa-title">¡ATENCIÓN!</h1>
        <p className="joa-txt">
          Nuestros procesos de selección y contratación son totalmente gratis y
          no se realizan a través de terceras personas o intermediarios no
          oficiales. Las ofertas de empleo disponibles, se realizan a través de
          los sitios oficiales de Crediseguro, como en nuestra página web
          crediseguro.co, redes sociales y bolsas de empleo
          (computrabajo.com.co).
        </p>
      </div>
    </section>
  );
};

export default JobOfferAdviceSection;
