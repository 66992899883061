import React,{useState} from "react";

//Style
import './WhatsappButton.css';

const WhatsappButton = () => {

    const [hoverWa, setHoverWa] = useState(false);

  return (
    <div className="wa-btn-container">
      <a
        id="Formulario_Whatsapp"
        onMouseEnter={() => setHoverWa(true)}
        onMouseLeave={() => setHoverWa(false)}
        className="wa-btn-icon-container"
        title="Whatsapp"
        href="https://api.whatsapp.com/send?phone=573164739140&text=%C2%A1Hola!%20Quiero%20m%C3%A1s%20informaci%C3%B3n%20sobre%20el%20pago%20o%20financiaci%C3%B3n%20con%20CrediSeguro%2C%20mi%20nombre%20es..."
        target="_blank"
        rel="noreferrer"
      >
        <img
          className="wa-icon"
          src="https://pagina-otacc.s3.amazonaws.com/pages/mirador-de-la-sabana/assets/whatsapp-icon.svg"
          alt=""
          loading="lazy"
        />
      </a>
      <div
        className={hoverWa ? "wa-hover-element show" : "wa-hover-element hide"}
      >
        <h4>Contáctanos</h4>
      </div>
    </div>
  );
};

export default WhatsappButton;
