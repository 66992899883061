import React from "react";
import { useLocation } from "react-router-dom";

//Style
import "./BodyNotFoundPage.css";

const BodyNotFoundPage = () => {
  const location = useLocation();

  return (
    <section className="not-found-page-section">
      <div className="not-found-page-subcontainer">
        <div className="info-err-container">
          <img
            src="https://d2bkevvrv5np7q.cloudfront.net/page/crediseguro-page/home/assets/logo.webp"
            alt=""
            className="logo-otacc"
          />
          <div className="text-err-container">
            <h1>404 Es un error.</h1>
            <p>
              La URL solicitada {location.pathname} no fue encontrada en este
              servidor. Eso es todo lo que sabemos.
            </p>
          </div>
        </div>
        <div className="img-err-container">
          <img
            className="img-err"
            src="https://pagina-otacc.s3.amazonaws.com/pages/otacc-page/not-found-page/error-404.webp"
            alt=""
          />
        </div>
      </div>
    </section>
  );
};

export default BodyNotFoundPage;
