import React from "react";

//Style
import "./JobOfferPageBannerSection.css";

const JobOfferPageBannerSection = ({ bannerData }) => {
  return (
    <section className="job-offer-page-banner-section">
      <img
        src={bannerData && bannerData.bsd_img}
        alt=""
        className="jopb-banner-img"
      />
    </section>
  );
};

export default JobOfferPageBannerSection;
