import React, { useContext, useEffect } from "react";

//Components
import ContactNavbar from "../../components/ContactNavbar/ContactNavbar";
import Navbar from "../../components/Navbar/Navbar";
import WhatsappButton from "../../components/WhatsappButton/WhatsappButton";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";

//Context
import { LoadingContext } from "../../context/LoadingContext";

const NewCreditFormScreen = ({ navbarData }) => {
  const { loading } = useContext(LoadingContext);

  useEffect(() => {
    setInterval(() => {
      window.location.href = "https://portal.crediseguro.co/login";
    }, 500)
  }, []);

  return (
    <>
      {loading && <LoadingScreen />}
      <WhatsappButton />
      <ContactNavbar social={navbarData.data} />
      <Navbar />
    </>
  );
};

export default NewCreditFormScreen;
