import React from "react";
import { Link } from "react-router-dom";

//Style
import "./ButtonComponent.css";

const ButtonComponent = ({ btn }) => {
  return (
    <>
      {btn.isAnchor && (
        <a
          className="btn"
          href={btn.href}
          title={btn.title}
          target={btn.openTab ? "_blank" : ""}
          rel="noreferrer"
        >
          <strong>{btn.strongTxt}</strong>&nbsp;{btn.txt}
        </a>
      )}
      {!btn.isAnchor && (
        <Link
          className="btn"
          to={btn.href}
          title={btn.title}
          target={btn.openTab ? "_blank" : ""}
        >
          <strong>{btn.strongTxt}</strong>&nbsp;{btn.txt}
        </Link>
      )}
    </>
  );
};

export default ButtonComponent;
