import React from 'react';

//Style
import './LoadingScreen.css';

const LoadingScreen = () => {
  return (
    <div className='loading-container'>
        <img className='loading-logo' src="https://d2bkevvrv5np7q.cloudfront.net/page/crediseguro-page/home/assets/logo.webp" alt="" loading='lazy' />
    </div>
  )
}

export default LoadingScreen