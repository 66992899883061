import React from "react";

//Style
import './JobOfferSection.css';

const JobOfferSection = () => {
  return (
    <section className="job-offer-section" id="why-choose-us">
      <div className="bg-skew top"></div>
      <div className="job-offer-subcontainer">
        <h1 className="jo-title">Si eres asesor de seguros, <strong>¿Por qué trabajar con Crediseguro?</strong></h1>
        <div className="job-offer-info-container">
          <div className="jo-video-container">
            <img className="deco-video" src="https://d2bkevvrv5np7q.cloudfront.net/page/crediseguro-page/home/assets/deco-img.webp" alt="" loading="lazy" />
            <video className="jo-info-video" controls>
              <source src="https://d2bkevvrv5np7q.cloudfront.net/page/crediseguro-page/home/videos/info-video.mp4" type="video/mp4" />
            </video>
          </div>
          <div className="jo-content-container">
            <h2 className="jo-content-title">¡Proceso 100% Digital y Desembolso Inmediato!</h2>
            <div className="jo-text-content">
              <p>
                Especialistas en seguros y financiamos con todas las
                aseguradoras de Colombia
              </p>
              <p>
                Con Crediseguro tendrás un solo proceso de financiación para
                todos tus clientes con información centralizada en tiempo real,
                logrando incrementar tus renovaciones porque nosotros nos
                encargamos del seguimiento y del mantenimiento mensual de la
                cartera.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-skew bottom"></div>
    </section>
  );
};

export default JobOfferSection;
