import React from "react";

//Components
import WhatsappButton from "../../components/WhatsappButton/WhatsappButton";
import ContactNavbar from "../../components/ContactNavbar/ContactNavbar";
import Navbar from "../../components/Navbar/Navbar";
import JobOfferBody from "../../components/JobOfferBody/JobOfferBody";

const JobOfferScreen = ({ navbarData }) => {
  return (
    <>
      <WhatsappButton />
      <ContactNavbar social={navbarData.data} />
      <Navbar />
      <JobOfferBody />
    </>
  );
};

export default JobOfferScreen;
