import React, { useEffect } from "react";

//Component
import ContactNavbar from "../../components/ContactNavbar/ContactNavbar";
import Navbar from "../../components/Navbar/Navbar";
import WhatsappButton from "../../components/WhatsappButton/WhatsappButton";

const CreditTypeScreen = ({ navbarData }) => {
  useEffect(() => {
    setInterval(() => {
      window.location.href = "https://portal.crediseguro.co/login";
    }, 500)
  }, []);

  return (
    <>
      <WhatsappButton />
      <ContactNavbar social={navbarData.data} />
      <Navbar />
    </>
  );
};

export default CreditTypeScreen;
